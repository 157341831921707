import React from 'react';

import { Text, Image, Stack } from '@mantine/core';
import { useGetUserById } from 'hooks/api/deeplinks/useGetUserById';
import { useGetUserEventById } from 'hooks/api/deeplinks/useGetUserEventById';
import { useGetGroupById } from 'hooks/api/deeplinks/useGetGroupById';
import { useGetGroupEventById } from 'hooks/api/deeplinks/useGetGroupEventById';
import { useNavigate, useParams } from 'react-router-dom';
import appStoreImage from '../assets/app_store_image.png';
import playStoreImage from '../assets/play_store_image.png';
import homeImage from '../assets/home/home_image.png';
import { Helmet } from 'react-helmet-async';

const Deeplink = () => {
	const navigate = useNavigate();
	let response;
	let message;

	const { id } = useParams();

	if (!id) {
		navigate('/');
	}

	const decodedSlug =
		id &&
		!isNaN(+id) &&
		decodeURIComponent(
			id
				.replace(/\s+/g, '') // remove spaces
				?.replace(/[0-9a-f]{2}/g, '%$&') // add '%' before each 2 characters
		);

	if (decodedSlug) {
		const splitSlug = decodedSlug.split('/');

		if (splitSlug[0] === 'user') {
			const { data: user } = useGetUserById(splitSlug[1]);

			response = user;
			message = 'Add your friend on Shore now!';
		} else if (splitSlug[0] === 'group') {
			const { data: user } = useGetGroupById(splitSlug[1]);

			response = user;
			message = 'Join this group on Shore now!';
		} else if (splitSlug[0] === 'group-event') {
			const { data: user } = useGetGroupEventById(splitSlug[1]);

			response = user;
			message = 'Join this event on Shore now!';
		} else if (splitSlug[0] === 'user-event') {
			const { data: user } = useGetUserEventById(splitSlug[1]);

			response = user;
			message = 'Join this event on Shore now!';
		}
	}

	return (
		<div className="2xl:max-w-[1440px] mx-auto p-10 sm:p-24 h-full">
			<Helmet>
				<title>Shore App: Your sport community hub</title>
				<meta
					name="description"
					content="Your sport community hub that make sports easier."
				/>
				<meta
					property="og:image"
					key="og:image"
					content={`${process.env.BASE_URL}/images/opengraph_image.jpg`}
				/>
			</Helmet>
			{response ? (
				<Stack className="h-full justify-center items-center">
					<div className="relative pb-2">
						<Image
							src={response.image_url}
							alt="hero"
							fit="contain"
							height={150}
							radius={30}
						/>
					</div>
					<Text className="text-xl font-medium">{response.name}</Text>
					<Text className="text-xl text-primary-red font-semibold">
						{message}
					</Text>
					<div className="border-t my-2">
						<p className="text-lg sm:pt-12 pt-4 font-semibold text-center">
							Download now
						</p>
						<div className="flex flex-row gap-4 pt-2">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://apple.co/4aL4SSh"
							>
								<Image
									src={appStoreImage}
									alt="app_store_button"
									fit="contain"
									width={150}
								/>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://bit.ly/48HtJ7E"
							>
								<Image
									src={playStoreImage}
									alt="play_store_button"
									fit="contain"
									width={170}
								/>
							</a>
						</div>
					</div>
				</Stack>
			) : (
				<Stack className="h-full justify-center items-center">
					<div className="lg:w-[60%] md:w-[80%] w-full">
						<Image src={homeImage} alt="hero" fit="contain" className="pb-4" />
					</div>

					<Text className="text-primary-red text-3xl font-semibold">
						When sports meet connectivity
					</Text>
					<Text className="text-md text-primary-red">
						Your sport community hub that make sports easier.
					</Text>
					<div>
						<p className="text-lg sm:pt-12 pt-4 font-semibold text-center">
							Download now
						</p>
						<div className="flex flex-row gap-4 pt-2">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://apple.co/4aL4SSh"
							>
								<Image
									src={appStoreImage}
									alt="app_store_button"
									fit="contain"
									width={150}
								/>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://bit.ly/48HtJ7E"
							>
								<Image
									src={playStoreImage}
									alt="play_store_button"
									fit="contain"
									width={170}
								/>
							</a>
						</div>
					</div>
				</Stack>
			)}
		</div>
	);
};

export default Deeplink;
