import { Flex, FlexProps, Image, Anchor } from '@mantine/core';
import React, { PropsWithoutRef } from 'react';
import appPlayStoreImage from '../views/assets/app/app_playstore.png';
import appAppStoreImage from '../views/assets/app/app_appstore.png';

type Props = PropsWithoutRef<FlexProps>;

const AppStoreLogo: React.FC<Props> = ({ ...rest }) => {
	return (
		<Flex {...rest}>
			<Anchor
				href="https://apps.apple.com/my/app/shore-sport-connectivity/id6470120471"
				target="_blank"
			>
				<Image src={appAppStoreImage} alt="App AppStore Image" fit="contain" />
			</Anchor>
			<Anchor
				href="https://play.google.com/store/apps/details?id=com.shore.userapp&hl=ms"
				target="_blank"
			>
				<Image
					src={appPlayStoreImage}
					alt="App Playstore Image"
					fit="contain"
				/>
			</Anchor>
		</Flex>
	);
};

export default AppStoreLogo;
