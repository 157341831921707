import React from 'react';
import { Container, Stack, Text } from '../../components';

const Error500 = () => {
	return (
		<Container
			style={{
				display: 'grid',
				height: 'calc(100vh - 62px)',
				width: '100%',
				placeItems: 'center',
			}}
		>
			<Stack>
				<Text weight={600} size="24px">
					Oops, something is wrong.
				</Text>
				<Text size="16px">
					We’re not exactly sure what has happened, but our servers say
					something is wrong.
				</Text>
			</Stack>
		</Container>
	);
};

export default Error500;
