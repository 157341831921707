import React from 'react';
import { Helmet } from 'react-helmet-async';

const EndUserAgreement = () => {
	return (
		<div className="flex xl:flex-row flex-col gap-5 relative z-0 max-w-[1440px] mx-auto px-10 mb-60">
			<Helmet>
				<title>End User Agreement | Shore App</title>
				<meta
					name="description"
					content="Your sport community hub that make sports easier."
				/>
				<meta
					property="og:image"
					key="og:image"
					content={`${process.env.BASE_URL}/images/opengraph_image.jpg`}
				/>
			</Helmet>
			<div className="flex-1 pt-20 padding-x">
				<h1 className="2xl:text-[52px] sm:text-[34px] text-[20px] font-extrabold">
					End-User License Agreement (&quot;Agreement&quot;)
				</h1>
				<p className="text-[16px] text-black-100 font-light">
					Last updated: July 23, 2023
				</p>

				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Please read this End-User License Agreement (&quot;Agreement&quot;)
					carefully before clicking the &quot;I Agree&quot; button, downloading
					or using Shore (&quot;Application&quot;). By clicking the &quot;I
					Agree&quot; button, downloading or using the Application, you are
					agreeing to be bound by the terms and conditions of this Agreement.
					This Agreement is a legal agreement between you (either an individual
					or a single entity) and Shore sdn bhd and it governs your use of the
					Application made available to you by Shore App Enterprise. If you do
					not agree to the terms of this Agreement, do not click on the &quot;I
					Agree&quot; button and do not download or use the Application. The
					Application is licensed, not sold, to you by Shore SA for use strictly
					in accordance with the terms of this Agreement.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">License</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore App Enterprise grants you a revocable, non-exclusive,
					non-transferable, limited license to download, install and use the
					Application solely for your personal, non-commercial purposes strictly
					in accordance with the terms of this Agreement.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Restrictions
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					You agree not to, and you will not permit others to: license, sell,
					rent, lease, assign, distribute, transmit, host, outsource, disclose
					or otherwise commercially exploit the Application or make the
					Application available to any third party. copy or use the Application
					for any purpose other than as permitted under the above section
					&apos;License&apos;. modify, make derivative works of, disassemble,
					decrypt, reverse compile or reverse engineer any part of the
					Application. remove, alter or obscure any proprietary notice
					(including any notice of copyright or trademark) of Shore App
					Enterprise or its affiliates, partners, suppliers or the licensors of
					the Application.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Intellectual Property
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					The Application, including without limitation all copyrights, patents,
					trademarks, trade secrets and other intellectual property rights are,
					and shall remain, the sole and exclusive property of Shore App
					Enterprise.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Your Suggestions
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Any feedback, comments, ideas, improvements or suggestions
					(collectively, &quot;Suggestions&quot;) provided by you to Shore App
					Enterprise with respect to the Application shall remain the sole and
					exclusive property of Shore App Enterprise. Shore App Enterprise shall
					be free to use, copy, modify, publish, or redistribute the Suggestions
					for any purpose and in any way without any credit or any compensation
					to you.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Modifications to Application
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore App Enterprise reserves the right to modify, suspend or
					discontinue, temporarily or permanently, the Application or any
					service to which it connects, with or without notice and without
					liability to you.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Updates to Application
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore App Enterprise may from time to time provide enhancements or
					improvements to the features/functionality of the Application, which
					may include patches, bug fixes, updates, upgrades and other
					modifications (&quot;Updates&quot;). Updates may modify or delete
					certain features and/or functionalities of the Application. You agree
					that Shore App Enterprise has no obligation to (i) provide any
					Updates, or (ii) continue to provide or enable any particular features
					and/or functionalities of the Application to you. You further agree
					that all Updates will be (i) deemed to constitute an integral part of
					the Application, and (ii) subject to the terms and conditions of this
					Agreement.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Third-Party Services
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					The Application may display, include or make available third-party
					content (including data, information, applications and other products
					services) or provide links to third-party websites or services
					(&quot;Third-Party Services&quot;). You acknowledge and agree that
					Shore App Enterprise shall not be responsible for any Third-Party
					Services, including their accuracy, completeness, timeliness,
					validity, copyright compliance, legality, decency, quality or any
					other aspect thereof. Shore App Enterprise does not assume and shall
					not have any liability or responsibility to you or any other person or
					entity for any Third-Party Services. Third-Party Services and links
					thereto are provided solely as a convenience to you and you access and
					use them entirely at your own risk and subject to such third
					parties&apos; terms and conditions.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Privacy Policy
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore App Enterprise collects, stores, maintains, and shares
					information about you in accordance with its Privacy Policy. By
					accepting this Agreement, you acknowledge that you hereby agree and
					consent to the terms and conditions of our Privacy Policy.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Term and Termination
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					This Agreement shall remain in effect until terminated by you or Shore
					SA. Shore App Enterprise may, in its sole discretion, at any time and
					for any or no reason, suspend or terminate this Agreement with or
					without prior notice. This Agreement will terminate immediately,
					without prior notice from Shore App Enterprise, in the event that you
					fail to comply with any provision of this Agreement. You may also
					terminate this Agreement by deleting the Application and all copies
					thereof from your mobile device or from your computer. Upon
					termination of this Agreement, you shall cease all use of the
					Application and delete all copies of the Application from your mobile
					device or from your computer. Termination of this Agreement will not
					limit any of Shore App Enterprise&apos;s rights or remedies at law or
					in equity in case of breach by you (during the term of this Agreement)
					of any of your obligations under the present Agreement.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Amendments to this Agreement
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore App Enterprise reserves the right, at its sole discretion, to
					modify or replace this Agreement at any time. If a revision is
					material we will provide at least 30 days&apos; notice prior to any
					new terms taking effect. What constitutes a material change will be
					determined at our sole discretion. By continuing to access or use our
					Application after any revisions become effective, you agree to be
					bound by the revised terms. If you do not agree to the new terms, you
					are no longer authorized to use the Application.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Contact Information
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					If you have any questions about this Agreement, please contact us :
					info@shoresport.app
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Entire Agreement
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					The Agreement constitutes the entire agreement between you and Shore
					App Enterprise regarding your use of the Application and supersedes
					all prior and contemporaneous written or oral agreements between you
					and Shore App Enterprise. You may be subject to additional terms and
					conditions that apply when you use or purchase other Shore App
					Enterprise&apos;s services, which Shore App Enterprise will provide to
					you at the time of such use or purchase.
				</p>
			</div>
		</div>
	);
};

export default EndUserAgreement;
