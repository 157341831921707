import React from 'react';

import {
	Box,
	CustomContainer,
	Flex,
	ViewContainer,
	Image,
	Text,
	Container,
} from 'components';
import eventTitleImage from '../assets/event/event_title.png';
import eventImage from '../assets/event/event_image.png';
import wePayYouPlayImage from '../assets/event/we_pay_you_play.png';

const Events = () => {
	return (
		<ViewContainer title="Events">
			<CustomContainer>
				<Flex
					align="center"
					className="flex-col lg:flex-row sm:justify-between gap-8"
				>
					<Flex direction="column" className="gap-6 ">
						<Box className="w-[220px] sm:w-[350px] lg:w-[500px]">
							<Image
								src={eventTitleImage}
								alt="Event Title Image"
								fit="contain"
							/>
						</Box>
						<Text className="text-mid-grey text-xs md:text-md lg:text-lg text-center lg:text-left">
							Explore exciting sports events with Shore
						</Text>
					</Flex>

					<Box className="w-[150px] sm-[200px] lg:w-[300px]">
						<Image src={eventImage} alt="Event Image" fit="contain" />
					</Box>
				</Flex>
			</CustomContainer>
			<Container className="bg-light-grey max-w-none mt-6">
				<CustomContainer className="py-10">
					<Flex className="gap-6 sm:gap-10 flex-wrap justify-center lg:justify-start">
						<Box
							component="a"
							className="bg-white w-[200px] sm:w-[300px] rounded-xl overflow-hidden"
							href="https://www.facebook.com/permalink.php?story_fbid=269742922821652&id=100093578396949"
							target="_blank"
						>
							<Box className="w-full">
								<Image src={wePayYouPlayImage} alt="Event Image" fit="cover" />
							</Box>
							<Text className="font-semibold text-xl sm:text-2xl text-center p-4">
								We Pay, You Play
							</Text>
						</Box>
					</Flex>
				</CustomContainer>
			</Container>
		</ViewContainer>
	);
};

export default Events;
