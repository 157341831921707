import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
	return (
		<div className="flex xl:flex-row flex-col gap-5 relative z-0 max-w-[1440px] mx-auto px-10 mb-60">
			<Helmet>
				<title>Private Policy | Shore App</title>
				<meta
					name="description"
					content="Your sport community hub that make sports easier."
				/>
				<meta
					property="og:image"
					key="og:image"
					content={`${process.env.BASE_URL}/images/opengraph_image.jpg`}
				/>
			</Helmet>
			<div className="flex-1 pt-20 padding-x">
				<h1 className="2xl:text-[52px] sm:text-[34px] text-[20px] font-extrabold">
					Privacy Policy
				</h1>
				<p className="text-[16px] text-black-100 font-light">
					Last updated: July 23, 2023
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-2">
					Shore App Privacy Policy
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					This Privacy Policy governs the manner in which Shore collects, uses,
					maintains and discloses information collected from users (each, a
					&quot;User&quot; or &quot;You&quot; and in the possessive
					form,&quot;Your&quot;) of the Shore application and websites (
					&quot;Shore&quot;, &quot;Shore App&quot;, “App&quot;, “Application”
					and “Websites”). This privacy policy applies to the application and
					all products and services offered by Shore sdn bhd, and applies to all
					correspondence and communications between Shore sdn bhd and its Users
					and shall be binding on the parties, unless so specified herein.{' '}
					<br /> <br />
					These services offered by Shore involve a great deal of trust on their
					part. We take this trust very seriously, and make it our highest
					priority to ensure the security and confidentiality of the Personal
					Data provided to our Users. Please read the following policy to learn
					about our privacy practices. <br /> <br /> By downloading and/or using
					the App and/or benefiting from the services, Users expressly consent
					to our collection, use and disclosure of your information in
					accordance with the terms of this Policy.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Collection of information you provide to us
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We collect information you provide directly to us. The types of
					information we may collect include:
				</p>
				<ul className="list-disc mx-4">
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">Account Information,</b> such as your name,
						email address, password, age, gender, photos, bio and any other
						information you choose to provide;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">Sport Information,</b> such as the names,
						email addresses and other information about individuals you invite
						to use our Services, register to receiving coaching services or
						authorize to receive notifications; and
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">Information About Others,</b> such as the
						sports and geolocation, level you play, availability, preferred
						practice time;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">
							Other Information You Choose to Provide,
						</b>{' '}
						such as when you participate in a survey, contest, promotion, quiz
						or interactive area of the Services, request technical or customer
						support, communicate with us via third-party social media services
						or otherwise communicate with us.
					</li>
				</ul>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Information we collect automatically when you use the services
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					When you access or use our Services, we automatically collect
					information about you, including:{' '}
				</p>
				<ul className="list-disc mx-4">
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">Log Information:</b> We collect log
						information about your access to and use of our Services, including
						the type of browser you use, access times, pages viewed, your IP
						address and the page you visited before navigating to our Services.
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">Device Information:</b> We collect
						information about the computer or mobile device you use to access
						our Services, including the hardware model, operating system and
						version, unique device identifiers and mobile network information.
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">Location Information:</b> With your consent
						(e.g. if you permit your mobile device to send us your latitude and
						longitude), we will collect location information when you access or
						use our Services and, if permitted by your device settings, in the
						&quot;background&quot; (i.e. at times when you are not directly
						using our Services). For more details, please see &quot;Your
						Choices&quot; below.
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						<b className="font-bold">
							Information collected by cookies and other tracking technologies
						</b>
						We and our service providers use various technologies to collect
						information, including cookies, web beacons, web storage and device
						identifiers. For more information about cookies, and how to disable
						them, please see &quot;Your Choices&quot; below.
					</li>
				</ul>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Information we collect from other sources
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We may also obtain information from other sources and combine that
					with information we collect through our Services. For example, we may
					collect information about you from third parties, including from other
					users, identity verification services, mailing list providers and
					publicly available sources. If you create or log into your account
					through a social media site, we will have access to certain
					information from that site, such as your name, account information and
					email address, in accordance with the authorization procedures
					determined by such social media site.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Use of information
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We may use information about you for various purposes, including to:
				</p>
				<ul className="list-disc mx-4">
					<li className="text-[16px] text-black-100 font-light mt-4">
						Provide, maintain and improve our Services;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Provide and deliver the products and services you request;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Manage your account and send you related information, including
						confirmations, updates, technical notices, security alerts and
						support and administrative messages;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Respond to your comments, questions and requests and provide
						customer service;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Communicate with you about products, services, offers, promotions,
						rewards, and events offered by Shore and others, and provide news
						and information we think will be of interest to you;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Monitor and analyze data, trends, usage and activities in connection
						with our Services;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Measure the effectiveness of advertising, content, features and
						activities, and perform other data analytics;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Detect, investigate and prevent fraudulent transactions and other
						illegal activities and protect the rights and property of Shore and
						others;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Personalize and improve our Services and provide advertisements,
						content and features that match user profiles or interests;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Facilitate contests, sweepstakes and promotions and process and
						deliver entries and rewards;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Link or combine with information we get from others to help
						understand your needs and provide you with a better experience; and
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Carry out any other purpose for which the information was collected.
					</li>
				</ul>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Sharing of information
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We may share information about you as follows or as otherwise
					described in this Privacy Policy:
				</p>
				<ul className="list-disc mx-4">
					<li className="text-[16px] text-black-100 font-light mt-4">
						With clubs, vendors, consultants and other service providers who
						need access to such information to carry out work or perform
						services on our behalf;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						When you create a profile, participate in the interactive areas of
						our Services or interact with us on third-party social media sites,
						certain information that you provide may be displayed publicly, such
						as your name, profile photo and other information you choose to
						provide;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						In response to a request for information if we believe disclosure is
						in accordance with, or required by, any applicable law, rule,
						regulation or legal process;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						If we believe your actions are inconsistent with our user agreements
						or policies, or to protect the rights, property or safety of Shore
						or others;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						In connection with, or during negotiations of, any merger, sale of
						company assets, financing or acquisition of all or a portion of our
						business by another company;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						Between and among Shore and any current or future parent, subsidiary
						and/or affiliated company;
					</li>
					<li className="text-[16px] text-black-100 font-light mt-4">
						On social networks such as Facebook, Instagram, Google, Twitter,
						etc…
					</li>
				</ul>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We may also share aggregated or de-identified information, which
					cannot reasonably be used to identify you.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Social sharing features
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					The Services may offer social sharing features and other integrated
					tools (such as the Facebook &quot;Like&quot; button), which let you
					share actions you take on our Services with other media. Your use of
					such features enables the sharing of information with your friends or
					the public, depending on the settings you establish with the entity
					that provides the social sharing feature. For more information about
					the purpose and scope of data collection and processing in connection
					with social sharing features, please visit the privacy policies of the
					entities that provide these features.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Advertising and analytics services provided by others
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We may allow others to serve advertisements on our behalf and provide
					analytics services. These entities may use cookies, web beacons,
					device identifiers and other technologies to collect information about
					your use of our Services and other applications and websites,
					including your IP address, web browser, device type, pages viewed,
					time spent on pages, links clicked and conversion information. This
					information may be used by us and others to, among other things,
					analyze and track data, determine the popularity of certain content,
					deliver advertising and content targeted to your interests on our
					Services and other applications and websites and better understand
					your online activity.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Your choices account information
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					You may access, update, correct or delete information about you at any
					time by accessing your account dashboard. If you wish to deactivate
					your account, please visit our App Settings page and click on “delete
					your account”. Note that we may retain certain information as required
					by law or for legitimate business purposes. We may also retain cached
					or archived copies of information about you for a certain period of
					time.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Location information
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					When you first launch any of our mobile applications that collect
					location information, you will be asked to consent to the
					application&apos;s collection of this information. If you initially
					consent to our collection of location information, you can
					subsequently stop the collection of this information at any time by
					changing the preferences on your mobile device. You may also stop our
					collection of location information by following the standard uninstall
					process to remove all of our mobile applications from your device.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Promotional communications
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					You may opt out of receiving promotional emails and text messages from
					Shore by following the instructions in those emails or messages. If
					you opt out, we may still send you non-promotional communications,
					such as those about your account or our ongoing business relations.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Push notifications
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					With your consent, we may send promotional and non-promotional push
					notifications or alerts to your mobile device. You can deactivate
					these messages at any time by changing the notification settings on
					your mobile device or within our mobile applications.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Transfer to and use of your data in other countries
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Your personal information may be stored in a secured and encrypted
					form overseas (e.g. in data storage and cloud computing facilities
					operated by us (or by third parties on our behalf)).The server on
					which the Shore application is hosted may be outside the country from
					which you access the Shore application and may be outside your country
					of residence. Your data may be transferred across country borders and
					also to countries where data protection laws provide a lower level of
					protection than in your country of residence. We implement measures,
					such as contractual confidentiality and non-use obligations, to ensure
					that your data are kept secure.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">Cookies</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We may use standard technology called ‘cookies’ on the Shore websites
					and applications. Cookies are small pieces of information that are
					stored by your browser on your computer’s hard drive. Our cookies
					enable us to identify you and to store information between visits.
					Most browsers automatically accept cookies, but you can usually change
					your browser to prevent cookies being stored, however if you do turn
					cookies off this will limit the service that we are able to provide to
					you. Please ensure that your browser’s cookie settings are as you
					wish.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">Security</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					We have security procedures in place to protect our user database from
					loss and misuse, and we only allow access to it when necessary, and
					then under strict guidelines as to what use may be made of the
					personal data contained within it. This website may contain links to
					other websites. We have no control over and are not responsible for,
					the content of, and the privacy policies or security of, such sites.
					We recommend that you check the privacy and security policies of each
					website you visit.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Your right to access and manage your personal data
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					You have the right to receive information about the personal data we
					hold about you and to have your data amended or deleted. A fee may be
					chargeable for any action by us at your request. You can amend your
					personal data in your Profile. To gain access to your personal data,
					or to have those amended or deleted, please contact us at
					info@shoresport.app
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Accuracy of your personal data
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					You are responsible for maintaining the accuracy of your personal data
					(i.e., the information you supplied to Shore upon registration) and
					for the security of your username and password; and for all activity
					that occurs on your account, even when content is posted by others
					on/via your account. You agree: (i) to notify Shore immediately of the
					discovery of any unauthorised use of any username or password or any
					other known or suspected breach of security; and (ii) to use
					reasonable efforts to immediately stop any unauthorised activity known
					or suspected. The address for notification is: info@shoresport.app
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Compliance with children’s online privacy protection
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Protecting the privacy of the very young is especially important to
					Shore. For that reason, Shore never collects or maintains information
					on its application or websites from individuals known and confirmed by
					the application or websites to be under 18 years of age and no part of
					the application is structured to attract anyone under 18 years of age.{' '}
					<br />
					<br />
					If a parent or guardian becomes aware that his or her child has
					provided us with Personal Information without their consent, he or she
					should contact us at info@shoresport.app. If we become aware that a
					child under 18 has provided us with Personal Information, we will take
					steps to delete such information from our files.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Changes to this privacy policy
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore has the discretion to update and/or amend this privacy policy at
					any time. Shore encourages users to frequently check this page for any
					changes to stay informed about how Shore is helping to protect the
					personal information it collects. The user hereby acknowledges and
					agrees that it is their responsibility to review this privacy policy
					periodically and become aware of modifications.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Your acceptance of these terms
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					By using Shore, you signify your acceptance of this policy and terms
					of service. If you do not agree to this policy, please do not proceed
					any further or use this App. Your continued use of Shore following the
					posting of changes to this policy will be deemed your acceptance of
					those changes.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Contacting us
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					If you have any questions about this Privacy Policy, the practices of
					this application, or your dealings with this application, please
					contact us at: info@shoresport.app
				</p>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
