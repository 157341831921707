import React from 'react';
import EndUserAgreement from 'views/EndUserAgreement';
import Home from 'views/Home';
import PrivacyPolicy from 'views/PrivacyPolicy';
import { HomeRoutes } from '../constants/Home.routes';
import PaymentTerm from 'views/PaymentTerm';

export const homeRoutes = [
	{
		name: 'Home',
		path: HomeRoutes.Home,
		element: <Home />,
	},
	{
		name: 'Privacy Policy',
		path: HomeRoutes.PrivacyPolicy,
		element: <PrivacyPolicy />,
	},
	{
		name: 'End User Agreement',
		path: HomeRoutes.EndUserAgreement,
		element: <EndUserAgreement />,
	},
	{
		name: 'Payment Term',
		path: HomeRoutes.PaymentTerm,
		element: <PaymentTerm />,
	},
];
