import { Container, ContainerProps } from '@mantine/core';
import React from 'react';
export type CustomContainerProps = React.PropsWithChildren<ContainerProps>;

export const CustomContainer = React.forwardRef<
	HTMLDivElement,
	CustomContainerProps
>(({ children, className, ...rest }, ref) => {
	return (
		<Container
			className={`2xl:max-w-[1440px] max-w-none px-8 2xl:px-0 m-0 mx-auto  ${
				className ?? ''
			}`}
			{...rest}
			ref={ref}
		>
			{children}
		</Container>
	);
});

CustomContainer.displayName = 'CustomContainer';
