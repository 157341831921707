import React from 'react';

import { Delete, FacebookOutlined, Instagram, East } from '@mui/icons-material';
import { IconProps } from './index';

export const iconsMapper = {
	delete: (props: Omit<IconProps, 'name'>) => <Delete {...props} />,
	facebook: (props: Omit<IconProps, 'name'>) => <FacebookOutlined {...props} />,
	instagram: (props: Omit<IconProps, 'name'>) => <Instagram {...props} />,
	east: (props: Omit<IconProps, 'name'>) => <East {...props} />,
};
