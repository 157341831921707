import React from 'react';
import { Button, Container, Stack, Text } from '../../components';
import { useNavigate } from '../../lib/navigate';

const Error403 = () => {
	const navigate = useNavigate();

	return (
		<Container
			style={{
				display: 'grid',
				height: 'calc(100vh - 62px)',
				width: '100%',
				placeItems: 'center',
			}}
		>
			<Stack>
				<Text weight={600} size="24px">
					Access Forbidden
				</Text>
				<Text size="16px">
					We are sorry, but you do not have access to this page.
				</Text>
				<Button className="bg-primary-red" onClick={() => navigate('/')}>
					Go to Home
				</Button>
			</Stack>
		</Container>
	);
};

export default Error403;
