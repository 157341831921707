import { useMutation } from 'react-query';
import { accountApi } from '../../../apis';

export function usePostAccountReactivate() {
	return useMutation(async (token: string) =>
		accountApi
			.post(`/reactivate`, {
				token: token,
			})
			.then(({ data }) => data)
	);
}
