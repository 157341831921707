import React from 'react';
import { Helmet } from 'react-helmet-async';

const EndUserAgreement = () => {
	return (
		<div className="flex xl:flex-row flex-col gap-5 relative z-0 max-w-[1440px] mx-auto px-10 mb-60">
			<Helmet>
				<title>Payment Term | Shore App</title>
				<meta
					name="description"
					content="Your sport community hub that make sports easier."
				/>
				<meta
					property="og:image"
					key="og:image"
					content={`${process.env.BASE_URL}/images/opengraph_image.jpg`}
				/>
			</Helmet>
			<div className="flex-1 pt-20 padding-x">
				<h1 className="2xl:text-[52px] sm:text-[34px] text-[20px] font-extrabold">
					Payment Term
				</h1>
				<p className="text-[16px] text-black-100 font-light">
					Last updated: August 18, 2024
				</p>

				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Please read these Payment Term (the &quot;Terms of Use&quot; or this
					&quot;Agreement&quot;) carefully. This Agreement is a legal agreement
					between you and Shore App Enterprise (the &quot;Company&quot;,
					&quot;we&quot; or &quot;us&quot;). By using this Service (as defined
					below), you aree to be bound by the terms of this Agreement. If you do
					not agree to the terms of this Agreement, please do not continue using
					the Application or the Service. The Company will give one (1) month
					notice of any amendments and additions to the Terms of Us and publish
					at{' '}
					<a href="https://shoresport.app" className="text-primary-blue">
						Shore App Website
					</a>{' '}
					or through the Application from time to time which your continued use
					of the Service will be deemed to confirm your acceptance of the
					revised Terms of Use.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[24px] text-black-100 font-bold mt-4">
					Use of the Shore Wallet
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Shore Wallet (the &quot;Application&quot;) may be used to pay the
					event fee, purchase of goods and services.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Fee and Charges
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Any transactions made using Shore Wallet in the same currency shall be
					no charges levied by the Company. The Company shall charge for any
					transactions in different currencies, such as topping up Shore Wallet
					using different currency debit or credit card than the Shore Wallet
					currency, paying the event fee in different currency from your Shore
					Wallet (&quot;Payment Charge&quot;) to account for the additional
					costs of the Company processing these international payments. You have
					the option to cancel the transaction if you do not agree to pay the
					addition Payment Charge. By proceeding with the transaction, you agree
					to the offered costs of the goods or services that may be added
					Payment Charge.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">Withdrawals</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					The Shore Wallet balance may be withdrawn to your bank account that
					you have registered in the Application. Any withdrawal shall take
					three (3) to seven (7) working days to cross check the transactions
					and process the request. You are entitled to two (2) withdraw balance
					a week and renew every first day of the week. Any updates to your bank
					account need verification with biometric if enabled, else phone number
					verification. The Company may decide to reverse or reject any
					withdrawals at any time and at its sole dicretion.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">Refunds</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					In the case of a Transaction that is improperly processed due to our
					mistake, we shall at your request immediately refund the Transaction
					Amount to your Shore Wallet. You agree to raise any complaints and
					disputes about incorrect Service within one (1) month of the
					transaction, after which time we will have no obligation to
					investigate or act upon your notification or provide a refund. Any
					fees or charges may not be refunded if mentioned to account for the
					additional costs of the Company processing these payments. Any refund
					request shall take three (3) to seven (7) working days to cross check
					the transactions and process the request.
				</p>
				<p className="text-[16px] text-black-100 font-bold mt-4">
					Closing Shore Wallet
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Either the Company or you may termiante this Agreement. Following
					termination of this Agreement, you should clear any amount and empty
					your balance in the Application. In the event that you require us to
					transfer any remaining balance in the Application to your nominated
					bank account, we reserve the right to charge a fee for the same.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[24px] text-black-100 font-bold mt-4">
					Shore User and Group Event
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					Any cancellation of the event or leave event shall refund to your
					Shore Wallet immediately. Any charges or fees are not refundable to
					account for the additional costs of the Company processing these
					payments. By using the Service, you agree that you will not make fake
					events or cause nuisance, annoyance, inconvenience. You shall pay late
					cancellation fees or receive warning for cancelling the event late
					without prior notice to players. Players shall pay the additional fee
					for leaving the event late, the fee will be deducted from the payment
					made to join the event earlier. Event host shall receive the payments
					upon confirmation from players or in the next 24 hours from the event,
					whichever comes first. We reserve the right to ban your account or
					freeze your Shore Wallet for any misuse of the Service such as
					creating fake event, intention to leave event late, sending or storing
					any unlawful material or for fraudulent purposes, trying to harm the
					Service, the Application and/or the Software in any way whatsoever.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[24px] text-black-100 font-bold mt-4">
					Third Party Interactions
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					By using the Service, your interactions and transactions with
					third-party providers are entirely your responsibility, and you assume
					any associated risks. The Company shall have no liability, obligation
					or responsibility for any such correspondence, purchase, transaction
					or promotion between you and any such third-party.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[24px] text-black-100 font-bold mt-4">Warranties</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					You represent and warrant to us. All information provided by you is
					true, correct and complete in all material aspects as at the date of
					this Agreement. Performance of all your obligations contemplated under
					this Agreement does not violate any Applicable Law. It is your
					responsibility for any damage to your mobile device or loss of data
					that results from the download of the software. We may, from time to
					time, need to request further information or confirmation of you and
					you agree to provide this information of confirmation.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[24px] text-black-100 font-bold mt-4">
					Liability and Indemnity
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					The Comapny does not represent or warrant the use of the Service, and
					the Application will meet your requirements or expectations and is
					secure, timely, uninterrupted or error-free or operate in combination
					with any other hardware, software, system or data. We will not be
					liable if the Beneficiary Bank fails to process the transaction
					correctly. To the fullest extent permitted by law, the Company shall
					not be liable for any claim, loss, damage, data loss, costs or
					expenses incurred (whether direct or consequential), suffered or
					sustained by you arising from or in connection with you use of the
					Service and the Applications.
				</p>
				<div className="border border-b-[0.5px] border-gray-200 my-5"></div>
				<p className="text-[24px] text-black-100 font-bold mt-4">
					Data Protection and Confidentiality
				</p>
				<p className="text-[16px] text-black-100 font-light mt-2 text-justify">
					By using the Service and the Application, we may collect your personal
					data. Details on how we use, collect and steps we take to protect your
					information are set out in our Privacy Policy,{' '}
					<a
						href="https://www.shoresport.app/privacy-policy"
						className="text-primary-blue"
					>
						Shore App | Privacy Policy
					</a>
					.
				</p>
			</div>
		</div>
	);
};

export default EndUserAgreement;
