import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../App';
import { homeRoutes } from './routes/homeRoutes';
import { deeplinkRoutes } from './routes/deeplinkRoutes';
import { accountRoutes } from './routes/accountRoutes';
import { errorRoutes } from './routes/errorRoutes';
import { eventRoutes } from './routes/eventlinkRoutes';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			...homeRoutes,
			...deeplinkRoutes,
			...accountRoutes,
			...eventRoutes,
			...errorRoutes,
		],
	},
]);

const Router = () => {
	return <RouterProvider router={router} />;
};

export default Router;
