import { useQuery } from 'react-query';
import { deeplinkApi } from '../../../apis';

export function useGetUserEventById(id?: number | string) {
	return useQuery(
		['id', id],
		async () =>
			deeplinkApi.get(`users/events/${id}`, {}).then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!id,
			refetchOnWindowFocus: false,
		}
	);
}
