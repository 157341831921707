import { AppShell } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Topbar from './Topbar';
import CustomFooter from './Footer';

const AppContainer: React.FC = () => {
	return (
		<AppShell header={<Topbar />} footer={<CustomFooter />} padding="0">
			<Outlet />
		</AppShell>
	);
};

export default AppContainer;
