import React from 'react';
import AppContainer from './layouts/AppContainer';
import AppStateProvider from 'providers/AppStateProvider';
import ThemeProvider from 'providers/ThemeProvider';
import QueryProvider from 'providers/QueryProvider';
import { HelmetProvider } from 'react-helmet-async';

function App() {
	return (
		<HelmetProvider>
			<QueryProvider>
				<AppStateProvider>
					<ThemeProvider>
						<AppContainer />
					</ThemeProvider>
				</AppStateProvider>
			</QueryProvider>
		</HelmetProvider>
	);
}

export default App;
