import React from 'react';
import {
	NavLinkProps as MantineNavLinkProps,
	Text,
	UnstyledButton,
	Stack,
	Container,
} from '@mantine/core';
import { useNavigate } from '../lib/navigate';
import { useLocation } from 'react-router-dom';
import { HomeRoutes } from 'router/constants/Home.routes';

export type NavLinkProps = {
	to?: string;
} & Omit<MantineNavLinkProps, 'icon'>;

const NavLink = React.forwardRef<HTMLButtonElement, NavLinkProps>(
	({ to, label }, ref) => {
		const navigate = useNavigate();
		const { pathname } = useLocation();
		const isActive = to ? pathname === to : false;

		const Component = () => (
			<Stack spacing="0">
				<Container></Container>
				<UnstyledButton
					className="font-semibold text-sm text-center py-1"
					onClick={() => navigate(to ?? HomeRoutes.Home)}
					ref={ref}
				>
					<Text weight="600">{label}</Text>
				</UnstyledButton>
				<Container
					className={isActive ? 'bg-primary-red h-1 w-12' : '' + 'h-1 w-12'}
				></Container>
			</Stack>
		);

		return <Component />;
	}
);

NavLink.displayName = 'NavLink';

export default NavLink;
