import React from 'react';
import {
	NavLinkProps as MantineNavLinkProps,
	Text,
	Box,
	Menu,
} from '@mantine/core';
import { useNavigate } from '../lib/navigate';
import { useLocation } from 'react-router-dom';
import { HomeRoutes } from 'router/constants/Home.routes';

export type MenuLinkProps = {
	to?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
} & Omit<MantineNavLinkProps, 'icon'>;

const MenuLink = React.forwardRef<HTMLButtonElement, MenuLinkProps>(
	({ to, label, onClick }, ref) => {
		const navigate = useNavigate();
		const { pathname } = useLocation();

		const isActive = to ? pathname === to : false;

		const Component = () => (
			<Menu.Item
				className="p-0"
				onClick={(e) => {
					navigate(to ?? HomeRoutes.Home);
					onClick && onClick(e);
				}}
				ref={ref}
			>
				<Box
					className={
						(isActive ? 'bg-primary-red-200' : '') +
						' font-semibold text-sm px-2 py-3'
					}
				>
					<Text weight="600">{label}</Text>
				</Box>
			</Menu.Item>
		);

		return <Component />;
	}
);

MenuLink.displayName = 'MenuLink';

export default MenuLink;
