import React from 'react';
import { EventRoutes } from 'router/constants/Event.routes';
import Events from 'views/Events/Events';

export const eventRoutes = [
	{
		name: 'Events',
		path: EventRoutes.Events,
		element: <Events />,
	},
];
