import React, { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import '../assets/styles/index.scss';
import { GlobalStyles, theme } from './ThemeProvider/index';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const themeOverride: MantineThemeOverride = {
		primaryColor: 'secondary',
		colors: {
			secondary: [
				'#B8C9CB',
				'#A0BCBF',
				'#89B3B7',
				'#70ADB2',
				'#56ABB2',
				'#40A7B0',
				'#2DA3AD',
				'#348990',
				'#377479',
				'#386467',
			],
		},
		fontFamily:
			"Poppins, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
		components: {
			Button: {
				defaultProps: {
					color: 'red',
				},
			},
			Select: {
				styles: {
					input: {
						padding: '23px 18px',
					},
				},
			},
			MultiSelect: {
				styles: {
					input: {
						padding: '6px 18px',
					},
				},
			},
			TextInput: {
				styles: {
					input: {
						padding: '23px 18px',
					},
				},
			},
			NumberInput: {
				styles: {
					input: {
						padding: '23px 18px',
					},
				},
			},
			NavLink: {
				styles: () => ({
					root: {
						height: '48px',
					},
					label: {
						fontSize: '16px',
					},
				}),
			},
		},
	};
	return (
		<>
			<GlobalStyles />
			<MantineProvider withNormalizeCSS withGlobalStyles theme={themeOverride}>
				<StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
			</MantineProvider>
		</>
	);
};

export default ThemeProvider;
export const useTheme = () => theme;
