import React, { PropsWithChildren } from 'react';

type TAppState = {
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
};

const initialState: TAppState = {
	isDirty: false,

	setDirty: () => {
		return;
	},
};

const AppState = React.createContext<TAppState>(initialState);

const AppStateProvider: React.FC<PropsWithChildren<unknown>> = ({
	children,
}) => {
	const [appState, setAppState] = React.useState<TAppState>({
		...initialState,
		isDirty: false,
	});

	const setDirty = React.useCallback(
		(dirty: boolean) =>
			setAppState((p) => ({
				...p,
				isDirty: dirty,
			})),
		[]
	);

	const value = {
		...appState,

		setDirty,
	};

	return <AppState.Provider value={value}>{children}</AppState.Provider>;
};

export default AppStateProvider;

export function useAppState() {
	return React.useContext(AppState);
}
