import React from 'react';
import { DeeplinkRoutes } from '../constants/Deeplink.routes';
import Deeplink from 'views/Deeplinks/Deeplink';

export const deeplinkRoutes = [
	{
		name: 'Deep Link',
		path: DeeplinkRoutes.Deeplink,
		element: <Deeplink />,
	},
];
