import {
	Group,
	Header,
	HeaderProps,
	Image,
	UnstyledButton,
	Button,
	Anchor,
	Flex,
	Text,
	Menu,
	Box,
	Burger,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { useNavigate } from '../lib/navigate';
import shoreLogo from '../views/assets/logo.svg';
import NavLink from './NavLink';
import { HomeRoutes } from 'router/constants/Home.routes';
import { CustomContainer } from 'components';
import { useDisclosure } from '@mantine/hooks';
import MenuLink from './MenuLink';
import { useLocation } from 'react-router-dom';
import { EventRoutes } from 'router/constants/Event.routes';

export type TopbarProps = Omit<HeaderProps, 'children' | 'height'>;

const Topbar = React.forwardRef<HTMLElement, TopbarProps>(
	({ ...rest }, ref) => {
		const navigate = useNavigate();
		const location = useLocation();

		const platform = window.navigator.appVersion;
		let redirectUrl = 'https://linktr.ee/shore.sport';

		if (platform.indexOf('iPhone') !== -1) {
			redirectUrl =
				'https://apps.apple.com/my/app/shore-sport-connectivity/id6470120471';
		} else if (platform.indexOf('Android') !== -1) {
			redirectUrl =
				'https://play.google.com/store/apps/details?id=com.shore.userapp&pcampaignid=web_share';
		}

		const [opened, { toggle }] = useDisclosure(false);
		useEffect(() => {
			if (opened) toggle();
		}, [location]);

		return (
			<Header {...rest} height="80px" ref={ref} className="border-none">
				<CustomContainer className="h-full">
					<Flex justify="space-between" align="center" className="h-full">
						<UnstyledButton onClick={() => navigate('/')}>
							<Image
								width={118}
								height={50}
								fit="contain"
								src={shoreLogo}
							></Image>
						</UnstyledButton>
						<Group spacing="xl" className="hidden md:flex">
							<NavLink label="Home" to={HomeRoutes.Home} />
							<NavLink label="Events" to={EventRoutes.Events} />
							<Button
								className="bg-primary-red rounded-full"
								onClick={(e) => {
									e.preventDefault();
									window.open(redirectUrl, '_blank', 'noopener,noreferrer');
								}}
							>
								<Text weight="600" className="text-white text-sm">
									GET THE APP
								</Text>
							</Button>
						</Group>
						<Box className="block md:hidden">
							<Menu shadow="md" width={200}>
								<Menu.Target>
									<Burger opened={opened} onClick={toggle} />
								</Menu.Target>

								<Menu.Dropdown className="p-2">
									<MenuLink
										label="Home"
										to={HomeRoutes.Home}
										onClick={toggle}
									/>
									<MenuLink
										label="Events"
										to={EventRoutes.Events}
										onClick={toggle}
									/>
									<Menu.Item className="p-2">
										<Box className="bg-primary-red p-2 rounded-full">
											<Anchor
												className="hover:no-underline"
												href={redirectUrl}
												target="_blank"
											>
												<Text
													weight="600"
													className="text-white text-sm text-center"
												>
													GET THE APP
												</Text>
											</Anchor>
										</Box>
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						</Box>
					</Flex>
				</CustomContainer>
			</Header>
		);
	}
);

Topbar.displayName = 'Topbar';

export default Topbar;
