import React from 'react';
import { Box, Container, Flex, Image, Text } from '@mantine/core';
import homeImage from './assets/home/home_image.png';
import { CustomContainer, ViewContainer } from 'components';
import homeTitleImage from './assets/home/home_title.png';
import homePhone1Image from './assets/home/home_phone_1.png';
import homePhone2Image from './assets/home/home_phone_2.png';
import homePhone3Image from './assets/home/home_phone_3.png';
import homePhone4Image from './assets/home/home_phone_4.png';
import homePhone5Image from './assets/home/home_phone_5.png';
import homePhone6Image from './assets/home/home_phone_6.png';
import AppStoreLogo from 'components/AppStoreLogo';

const Home = () => {
	return (
		<ViewContainer title="Home">
			<CustomContainer>
				<Flex direction="column" align="center" gap="sm">
					<Box className="w-[300px] sm:w-[400px] lg:w-[500px]">
						<Image src={homeTitleImage} alt="Home Title Image" fit="contain" />
					</Box>
					<AppStoreLogo gap="sm" className="w-60 lg:w-80" />
				</Flex>
				<Container className="py-10 px-0 max-w-none">
					<Image
						src={homeImage}
						alt="App Playstore Image"
						fit="contain"
						width="100%"
					/>
				</Container>
			</CustomContainer>
			<CustomContainer className="mx-auto">
				<Flex className="gap-6 lg:gap-12 flex-col md:flex-row">
					<Flex direction="column" className="gap-6 lg:gap-12">
						<Box className="bg-light-grey pt-2 rounded-3xl md:h-[400px] lg:h-[550px] xl:h-[650px] overflow-hidden">
							<Flex direction="column">
								<Text className="font-bold text-3xl md:text-xl xl:text-3xl p-4">
									The Ultimate Sports Social App
								</Text>
								<Image
									src={homePhone1Image}
									alt="Home Phone 1 Image"
									fit="cover"
									height="auto"
								/>
							</Flex>
						</Box>
						<Box className="bg-light-grey pt-2 rounded-3xl md:h-[280px] lg:h-[320px] xl:h-[380px] 2xl:h-[420px] overflow-hidden">
							<Flex direction="column">
								<Text className="font-semibold text-2xl md:text-lg xl:text-2xl p-4 text-center">
									Create Your Own Community
								</Text>
								<Image
									src={homePhone2Image}
									alt="Home Phone 2 Image"
									fit="contain"
									width="100%"
								/>
							</Flex>
						</Box>
					</Flex>
					<Flex direction="column" className="gap-6 lg:gap-12">
						<Box className="bg-light-grey pt-2 rounded-3xl md:h-[280px] lg:h-[320px] xl:h-[380px] 2xl:h-[420px] overflow-hidden">
							<Flex direction="column" align="center">
								<Text className="font-semibold text-2xl md:text-lg xl:text-2xl p-4">
									Connect with Friends Who Share Your Passion for Sports
								</Text>
								<Box className="w-9/12">
									<Image
										src={homePhone3Image}
										alt="Home Phone 3 Image"
										fit="contain"
										width="100%"
									/>
								</Box>
							</Flex>
						</Box>
						<Box className="bg-light-grey pt-2 rounded-3xl md:h-[400px] lg:h-[550px] xl:h-[650px] overflow-hidden">
							<Flex direction="column">
								<Text className="font-semibold text-2xl md:text-lg xl:text-2xl p-4 text-center">
									Switch Seamlessly Between Group & Personal Accounts
								</Text>
								<Image
									src={homePhone4Image}
									alt="Home Phone 4 Image"
									fit="contain"
									width="100%"
								/>
							</Flex>
						</Box>
					</Flex>
					<Flex direction="column" className="gap-6 lg:gap-12">
						<Box className="bg-light-grey pt-2 rounded-3xl md:h-[400px] lg:h-[550px] xl:h-[650px] overflow-hidden">
							<Flex direction="column">
								<Text className="font-semibold text-2xl md:text-lg xl:text-2xl p-4">
									Create & Search for Sport Events with One Click
								</Text>
								<Image
									src={homePhone5Image}
									alt="Home Phone 5 Image"
									fit="contain"
									width="100%"
								/>
							</Flex>
						</Box>
						<Box className="bg-light-grey rounded-3xl md:h-[280px] lg:h-[320px] xl:h-[380px] 2xl:h-[420px] overflow-hidden">
							<Flex direction="column" align="center">
								<Box className="w-9/12">
									<Image
										src={homePhone6Image}
										alt="Home Phone 6 Image"
										fit="contain"
										width="100%"
									/>
								</Box>

								<Text className="font-semibold text-2xl md:text-lg xl:text-2xl p-4 text-center">
									Manage Events Effortlessly
								</Text>
							</Flex>
						</Box>
					</Flex>
				</Flex>
			</CustomContainer>
		</ViewContainer>
	);
};

export default Home;
