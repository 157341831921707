import axios from 'axios';

export const apiRoutes: Record<
	'deeplinkApi' | 'accountApi',
	Record<string, string>
> = {
	deeplinkApi: {
		local: 'http://localhost:3030/api/v1/deeplinks',
		staging: 'https://staging-api.shoresport.app/api/v1/deeplinks',
		production: 'https://api.shoresport.app/api/v1/deeplinks',
	},
	accountApi: {
		local: 'http://localhost:3030/api/v1/auth/user',
		staging: 'https://staging-api.shoresport.app/api/v1/auth/user',
		production: 'https://api.shoresport.app/api/v1/auth/user',
	},
};

export const deeplinkApi = axios.create({
	baseURL:
		apiRoutes.deeplinkApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
});

export const accountApi = axios.create({
	baseURL:
		apiRoutes.accountApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
});
