import {
	FooterProps,
	Image,
	UnstyledButton,
	Footer,
	Container,
	Text,
	Anchor,
	Flex,
	Box,
} from '@mantine/core';
import React from 'react';
import { useNavigate } from '../lib/navigate';
import shoreLogo from '../views/assets/logo.svg';
import bannerImage from '../views/assets/banner.png';
import arrowRightImage from '../views/assets/arrow_right.png';
import appQrCodeImage from '../views/assets/app_qr_code.png';
import { HomeRoutes } from 'router/constants/Home.routes';
import Icon from 'assets/icons';
import { CustomContainer } from 'components';
import AppStoreLogo from 'components/AppStoreLogo';

export type CustomFooterProps = Omit<FooterProps, 'children' | 'height'>;

const CustomFooter = React.forwardRef<HTMLElement, CustomFooterProps>(
	({ ...rest }, ref) => {
		const navigate = useNavigate();

		return (
			<Footer
				className="relative border-none w-full mt-8"
				height="200px"
				{...rest}
				ref={ref}
			>
				<Container className="bg-light-grey w-full max-w-none py-6 md:py-10 my-2">
					<CustomContainer>
						<Flex
							justify="space-between"
							align="center"
							className="flex-column md:flex-row gap-4 md:gap-0"
						>
							<Flex className="gap-4 md:gap-0">
								<Box className="w-[120px] sm:w-[150px] xl:w-[200px]">
									<Image src={bannerImage} alt="Banner Image" fit="contain" />
								</Box>
								<Flex
									direction="column"
									justify="center"
									className="gap-0 md:gap-2"
								>
									<Text className="text-lg lg:text-2xl 2xl:text-3xl font-bold">
										What are you waiting for?
									</Text>
									<Text className="text-mid-grey text-xs md:text-md lg:text-lg">
										Let the Games Begin!
									</Text>
								</Flex>
							</Flex>
							<Box className="w-[50px] lg:w-[100px] 2xl:w-[150px] d-none lg:block">
								<Image
									src={arrowRightImage}
									alt="Home Arrow Right Image"
									fit="contain"
								/>
							</Box>

							<Flex direction="column" justify="center">
								<Text className="text-xs md:text-sm xl:text-md font-semibold">
									Scan to download
								</Text>
								<Flex className="gap-4">
									<Box className="w-[50px] sm:w-[80px] md:w-[100px]">
										<Image
											src={appQrCodeImage}
											alt="App QR Code Image"
											fit="contain"
										/>
									</Box>

									<Flex direction="column" justify="center" gap="xs">
										<Text className="text-tertiary-grey font-semibold text-xs md:text-md xl:text-xl 2xl:text-2xl">
											Shore: Sports & Connectivity
										</Text>
										<AppStoreLogo
											gap="sm"
											className="w-40 md:w-60 xl:w-72 2xl:w-80"
										/>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</CustomContainer>
				</Container>

				<CustomContainer className="h-32">
					<Flex direction="column" gap="xs" justify="center" className="h-full">
						<UnstyledButton onClick={() => navigate('/')}>
							<Image
								width={118}
								height={50}
								fit="contain"
								src={shoreLogo}
							></Image>
						</UnstyledButton>
						<Flex className="gap-10">
							<Flex className="gap-2 sm:gap-10 flex-col sm:flex-row">
								<UnstyledButton
									className="text-primary-red text-sm"
									onClick={() => navigate(HomeRoutes.PrivacyPolicy)}
								>
									Privacy Policy
								</UnstyledButton>
								<UnstyledButton
									className="text-primary-red text-sm"
									onClick={() => navigate(HomeRoutes.EndUserAgreement)}
								>
									Term of Use
								</UnstyledButton>
								<UnstyledButton
									className="text-primary-red text-sm"
									onClick={() => navigate(HomeRoutes.PaymentTerm)}
								>
									Payment Term
								</UnstyledButton>
							</Flex>
							<Flex
								className="flex-col sm:flex-row"
								gap="sm"
								align="center"
								justify="center"
							>
								<Text className="text-primary-red text-sm hover:no-underline">
									Follow Us
								</Text>
								<Flex gap="sm">
									<Anchor
										className="text-primary-red text-sm hover:no-underline"
										href="https://www.facebook.com/people/Shore-Sport/100093578396949/"
										target="_blank"
									>
										<Icon name="facebook" />
									</Anchor>
									<Anchor
										className="text-primary-red text-sm hover:no-underline"
										href="https://www.instagram.com/shore.sport.app/"
										target="_blank"
									>
										<Icon name="instagram" />
									</Anchor>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</CustomContainer>
				<Container className="h-full w-full relative p-0 m-0 max-w-none">
					<Container className="absolute z-10 top-2/3 left-0 right-0">
						<Text className="text-xs sm:text-sm text-center text-white">
							2024 Shore App Enterprise
						</Text>
						<Text className="text-xs sm:text-sm text-center text-white">
							All Rights Reserved ©
						</Text>
					</Container>
					<div className="bg-primary-red absolute bottom-0 w-full h-[150px] footer__lower__box"></div>
					<div className="bg-primary-red-500 absolute bottom-4 w-full h-[150px] footer__upper__box"></div>
				</Container>
			</Footer>
		);
	}
);

CustomFooter.displayName = 'CustomFooter';

export default CustomFooter;
