import React from 'react';
import { AccountRoutes } from '../constants/Account.routes';
import AccountReactivate from 'views/Accounts/AccountReactivate';

export const accountRoutes = [
	{
		name: 'Account Reactivate',
		path: AccountRoutes.AccountReactivate,
		element: <AccountReactivate />,
	},
];
